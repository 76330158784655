import React from 'react';
import { Users, Brain, Zap, Shield } from 'lucide-react';

const features = [
  {
    icon: Users,
    title: "Intelligent Meeting Assistant",
    description: "Attends meetings, captures insights, and drives action items"
  },
  {
    icon: Brain,
    title: "Process Automation",
    description: "Executes complex workflows across enterprise systems"
  },
  {
    icon: Zap,
    title: "Enterprise Integration",
    description: "Works with your existing enterprise software stack"
  },
  {
    icon: Shield,
    title: "Enterprise Security",
    description: "Bank-grade security and compliance controls"
  }
];

const Features = () => {
  return (
    <div className="mt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <div key={index} className="pt-6">
              <div className="flow-root bg-gray-50 dark:bg-gray-800 rounded-lg px-6 pb-8 h-full hover:shadow-lg transition-shadow duration-300">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-white" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 dark:text-white tracking-tight">
                    {feature.title}
                  </h3>
                  <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;