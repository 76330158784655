import { z } from 'zod';

// Email regex for business domains
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|mil|biz|info|io|ai|co|uk|ca|au|de|fr|jp|cn|br|in|ru|nl|se|no|fi|dk|pl|ch|at|be|es|pt|gr|il|nz|mx|za|kr|sg|my|th|vn|ph|tr|ar|cl|co|pe|ve)$/i;

// Phone regex for format XXX-XXX-XXXX
const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

export const leadSchema = z.object({
  fullName: z.string()
    .min(2, 'Full name must be at least 2 characters')
    .max(100, 'Full name must be less than 100 characters')
    .regex(/^[a-zA-Z\s-']+$/, 'Name can only contain letters, spaces, hyphens, and apostrophes'),
    
  email: z.string()
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email must be less than 254 characters')
    .regex(emailRegex, 'Please enter a valid business email address'),
    
  companyName: z.string()
    .min(2, 'Company name must be at least 2 characters')
    .max(100, 'Company name must be less than 100 characters')
    .regex(/^[a-zA-Z0-9\s.,&'-]+$/, 'Company name contains invalid characters'),
    
  companySize: z.enum(['1-2', '3-10', '11-50', '51-200', '201-500', '501+']),
  
  title: z.string()
    .min(2, 'Title must be at least 2 characters')
    .max(100, 'Title must be less than 100 characters')
    .regex(/^[a-zA-Z0-9\s.,&'-]+$/, 'Title contains invalid characters'),
    
  useCase: z.enum([
    'cost-reduction',
    'productivity',
    'automation',
    'meetings',
    'support',
    'other'
  ]),
  
  aiExperience: z.enum([
    'ready',
    'evaluating',
    'planning',
    'exploring'
  ]),
  
  phoneNumber: z.string()
    .regex(phoneRegex, 'Phone number must be in format XXX-XXX-XXXX')
    .optional()
    .nullable(),
});

export type LeadFormData = z.infer<typeof leadSchema>;