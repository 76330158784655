import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthStore from '../store/authStore';

const Dashboard = () => {
  const { user } = useAuth0();
  const authUser = useAuthStore(state => state.user);

  if (!authUser?.subscription?.status === 'active') {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Subscription Required</h2>
          <p className="mt-2 text-gray-600">
            Please subscribe to access the dashboard and bot management features.
          </p>
          <button
            onClick={() => window.location.href = '/pricing'}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            View Plans
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-900">Welcome, {user?.name}</h1>
      {/* Dashboard content will be integrated with existing dashboard */}
    </div>
  );
};

export default Dashboard;