import React from 'react';

const steps = [
  {
    number: "1",
    title: "Initial Setup",
    description: "Connect your tools and configure preferences"
  },
  {
    number: "2",
    title: "Create AI Team",
    description: "Define roles and responsibilities"
  },
  {
    number: "3",
    title: "Test & Verify",
    description: "Confirm integrations and permissions"
  },
  {
    number: "4",
    title: "Go Live",
    description: "Launch your AI workforce"
  }
];

const metrics = [
  {
    label: "Setup Time",
    value: "< 1 hour"
  },
  {
    label: "Success Rate",
    value: "98%"
  },
  {
    label: "Cost Savings",
    value: "80%"
  },
  {
    label: "ROI Timeline",
    value: "2 weeks"
  }
];

const QuickStartGuide = () => {
  return (
    <div className="bg-white dark:bg-gray-800 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Quick Start Guide
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Simple setup process gets you started quickly
          </p>
        </div>

        <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
          <div className="grid grid-cols-2 gap-6">
            {steps.map((step, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-700 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <div className="flex items-center mb-4">
                  <span className="w-8 h-8 flex items-center justify-center bg-indigo-600 dark:bg-indigo-500 rounded-full text-white font-bold">
                    {step.number}
                  </span>
                  <h3 className="ml-3 text-lg font-medium text-gray-900 dark:text-white">{step.title}</h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300">{step.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-gray-50 dark:bg-gray-700 rounded-xl p-8">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
              Implementation Metrics
            </h3>
            <div className="grid grid-cols-2 gap-6">
              {metrics.map((metric, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-600 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
                >
                  <div className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
                    {metric.value}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                    {metric.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStartGuide;