import React from 'react';
import { Clock } from 'lucide-react';

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const endDate = new Date('2024-12-31T23:59:59').getTime();
    const now = new Date().getTime();
    const distance = endDate - now;

    return {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000)
    };
  };

  const timeLeft = calculateTimeLeft();

  return (
    <div className="mt-12 bg-indigo-50 dark:bg-gray-800 border border-indigo-100 dark:border-gray-700 rounded-lg p-6">
      <div className="flex items-center justify-center space-x-2 text-indigo-600 dark:text-indigo-400">
        <Clock className="h-5 w-5" />
        <span className="font-semibold">Launch Pricing Ends In:</span>
      </div>
      <div className="mt-4 grid grid-cols-4 gap-4 text-center">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="bg-white dark:bg-gray-700 rounded-lg p-3 shadow-sm">
            <div className="text-3xl font-bold text-indigo-600 dark:text-indigo-400">
              {String(value).padStart(2, '0')}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 capitalize">{unit}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountdownTimer;