import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const SocialLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const handleGoogleLogin = () => {
    loginWithRedirect({
      connection: 'google-oauth2',
      appState: { returnTo: '/signup' },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  const handleMicrosoftLogin = () => {
    loginWithRedirect({
      connection: 'windowslive',
      appState: { returnTo: '/signup' },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  return (
    <div className="space-y-4">
      <button
        onClick={handleGoogleLogin}
        className="w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
        Continue with Google
      </button>

      <button
        onClick={handleMicrosoftLogin}
        className="w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        <img src="https://www.microsoft.com/favicon.ico" alt="Microsoft" className="w-5 h-5" />
        Continue with Microsoft
      </button>
    </div>
  );
};

export default SocialLogin;