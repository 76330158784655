import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail, Building, Users, Phone, Briefcase, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { leadSchema } from '../utils/validation';
import type { LeadFormData } from '../utils/validation';

const LeadCapture = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LeadFormData>({
    resolver: zodResolver(leadSchema),
    mode: 'onBlur'
  });

  const onSubmit = async (data: LeadFormData) => {
    try {
      const formData = new FormData();
      
      // Add form name
      formData.append('form-name', 'lead-capture');
      
      // Add all form fields
      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value.toString());
        }
      });

      // Submit to Netlify Forms
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      toast.success('Thank you! We will be in touch shortly.');
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('There was an error submitting your information. Please try again.');
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    e.target.value = formatted;
  };

  return (
    <form 
      name="lead-capture"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)} 
      className="space-y-6"
    >
      <input type="hidden" name="form-name" value="lead-capture" />
      <div hidden>
        <input name="bot-field" />
      </div>

      {/* Rest of the form fields remain the same */}
      {/* ... */}
    </form>
  );
};

export default LeadCapture;