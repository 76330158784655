import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    quote: "Our team productivity increased by 40% with Workforce AI's digital employees.",
    author: "Sarah Chen",
    title: "CTO, Enterprise Solutions Inc.",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&h=150&fit=crop"
  },
  {
    quote: "These AI employees transformed how we handle meetings and tasks.",
    author: "Michael Rodriguez",
    title: "Head of Operations, Global Tech Corp",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150&h=150&fit=crop"
  },
  {
    quote: "Seamless integration made adoption incredibly smooth for our teams.",
    author: "Emily Thompson",
    title: "Director of Innovation, Tech Innovators Ltd",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150&h=150&fit=crop"
  },
  {
    quote: "The ROI was clear within the first month of implementation.",
    author: "David Park",
    title: "VP of Technology, Global Solutions",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=150&h=150&fit=crop"
  }
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isAnimating, setIsAnimating] = React.useState(false);

  const goToNext = React.useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex + 2 >= testimonials.length ? 0 : prevIndex + 2));
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating]);

  const goToPrevious = React.useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex - 2 < 0 ? testimonials.length - 2 : prevIndex - 2));
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating]);

  React.useEffect(() => {
    const timer = setInterval(goToNext, 5000);
    return () => clearInterval(timer);
  }, [goToNext]);

  return (
    <div className="bg-white dark:bg-gray-800 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-white mb-8">
          What Our Clients Say
        </h2>
        <div className="relative">
          <div className="flex justify-between items-stretch gap-8">
            {[currentIndex, currentIndex + 1].map((index) => (
              <div
                key={index}
                className="flex-1 bg-gray-50 dark:bg-gray-700 rounded-xl p-8 shadow-sm transition-all duration-500"
              >
                <div className="mb-8 min-h-[72px]">
                  <p className="text-gray-800 dark:text-gray-200 text-lg line-clamp-3">
                    {testimonials[index].quote}
                  </p>
                </div>
                <div className="flex items-center mt-8">
                  <img
                    src={testimonials[index].image}
                    alt={`${testimonials[index].author}, ${testimonials[index].title}`}
                    className="h-12 w-12 rounded-full object-cover"
                    loading="lazy"
                    width="48"
                    height="48"
                  />
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900 dark:text-white">
                      {testimonials[index].author}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {testimonials[index].title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={goToPrevious}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white dark:bg-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-50 dark:hover:bg-gray-600"
            aria-label="Previous testimonial"
          >
            <ChevronLeft className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </button>
          <button
            onClick={goToNext}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white dark:bg-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-50 dark:hover:bg-gray-600"
            aria-label="Next testimonial"
          >
            <ChevronRight className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSlider);