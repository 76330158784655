import React from 'react';
import { Users, BookOpen, Video, Zap, ClipboardList, Brain } from 'lucide-react';

const useCases = [
  {
    icon: Users,
    title: "Meeting Management",
    description: "Automates meeting scheduling, notes, and follow-ups"
  },
  {
    icon: BookOpen,
    title: "Team Training",
    description: "Delivers personalized training and tracks progress"
  },
  {
    icon: Video,
    title: "Meeting Attendance",
    description: "Represents your team in meetings across time zones"
  },
  {
    icon: Zap,
    title: "Process Automation",
    description: "Streamlines complex workflows across systems"
  },
  {
    icon: ClipboardList,
    title: "Project Management",
    description: "Coordinates tasks and maintains documentation"
  },
  {
    icon: Brain,
    title: "Strategic Analysis",
    description: "Delivers data-driven insights for decision making"
  }
];

const UseCases = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Elevate Your Operations
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            AI digital employees that scale with your business
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {useCases.map((useCase, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex items-center justify-center w-12 h-12 bg-indigo-100 dark:bg-indigo-900 rounded-md mb-4">
                <useCase.icon className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">{useCase.title}</h3>
              <p className="text-gray-500 dark:text-gray-300">{useCase.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases;