import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Mail, MapPin } from 'lucide-react';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <footer className="bg-gray-900 dark:bg-gray-950 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          <div className="md:col-span-5">
            <h3 className="text-lg font-semibold mb-4">Workforce AI</h3>
            <p className="text-gray-400 max-w-2xl">
              Revolutionizing enterprise productivity with AI-powered digital employees that seamlessly integrate with your team, automate complex workflows, and drive unprecedented business growth.
            </p>
          </div>
          <div className="md:col-span-3">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <button 
                  onClick={() => handleNavigation('/')} 
                  className="text-gray-400 hover:text-white"
                >
                  Home
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleNavigation('/pricing')} 
                  className="text-gray-400 hover:text-white"
                >
                  Pricing
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleNavigation('/signup')} 
                  className="text-gray-400 hover:text-white"
                >
                  Get Started
                </button>
              </li>
            </ul>
          </div>
          <div className="md:col-span-4 md:pl-4">
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <div className="space-y-3">
              <div className="flex items-center text-gray-400">
                <Mail className="h-5 w-5 mr-2 flex-shrink-0" />
                <a 
                  href="mailto:contact@workforce-ai.com" 
                  className="hover:text-white"
                >
                  contact@workforce-ai.com
                </a>
              </div>
              <div className="flex items-center text-gray-400">
                <MapPin className="h-5 w-5 mr-2 flex-shrink-0" />
                <span>New York, USA</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Workforce AI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;