import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Check } from 'lucide-react';
import { useStripe } from '../hooks/useStripe';
import CountdownTimer from '../components/CountdownTimer';
import GuaranteeSection from '../components/GuaranteeSection';
import TestimonialSlider from '../components/TestimonialSlider';
import { Link } from 'react-router-dom';

const plans = [
  {
    id: 'monthly',
    name: 'Monthly Plan',
    price: 600,
    regularPrice: 800,
    interval: 'month',
    features: [
      '1 AI Digital Employee',
      'Unlimited Meetings',
      'Meeting Summaries',
      'Priority Support',
      'Real-time Meeting Participation',
      'Intelligent Task Execution'
    ],
    priceDisplay: '$600',
    regularPriceDisplay: '$800',
    intervalDisplay: '/month'
  },
  {
    id: 'yearly',
    name: 'Yearly Plan',
    price: 5000,
    regularPrice: 7500,
    interval: 'year',
    features: [
      '1 AI Digital Employee',
      'Unlimited Meetings',
      'Meeting Summaries',
      'Priority Support',
      'Real-time Meeting Participation',
      'Intelligent Task Execution',
      '2 Months Free'
    ],
    priceDisplay: '$5,000',
    regularPriceDisplay: '$7,500',
    intervalDisplay: '/year',
    isPopular: true
  }
];

const Pricing = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { loading } = useStripe();

  const handleSubscribe = () => {
    navigate('/signup');
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 dark:text-white sm:text-center">Pricing Plans</h1>
          <p className="mt-5 text-xl text-gray-500 dark:text-gray-400 sm:text-center mb-8">
            Special launch pricing available now. Start with your AI digital employee today.
          </p>
          <CountdownTimer />
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`relative rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 ${
                plan.isPopular ? 'border-2 border-indigo-500' : 'border border-gray-200 dark:border-gray-700'
              } bg-white dark:bg-gray-800`}
            >
              {plan.isPopular && (
                <div className="absolute top-0 right-6 -translate-y-1/2 transform">
                  <div className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm font-semibold text-white">
                    Most Popular
                  </div>
                </div>
              )}
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">{plan.name}</h2>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900 dark:text-white">{plan.priceDisplay}</span>
                  <span className="text-base font-medium text-gray-500 dark:text-gray-400">{plan.intervalDisplay}</span>
                </p>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Regular price: <strike>{plan.regularPriceDisplay}{plan.intervalDisplay}</strike>
                </p>
                <Link
                  to="/signup"
                  className={`mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  } ${
                    plan.isPopular
                      ? 'text-white bg-indigo-600 hover:bg-indigo-700'
                      : 'text-indigo-700 dark:text-indigo-400 bg-indigo-50 dark:bg-gray-700 hover:bg-indigo-100 dark:hover:bg-gray-600'
                  }`}
                >
                  {loading ? 'Processing...' : 'Subscribe Now'}
                </Link>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 dark:text-gray-300 tracking-wide uppercase">
                  What's included
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                      <span className="text-sm text-gray-500 dark:text-gray-400">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <GuaranteeSection />
        <div className="mt-16">
          <TestimonialSlider />
        </div>
        
        <div className="mt-16 text-center">
          <Link
            to="/signup"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get Started Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pricing;