import { create } from 'zustand';
import { AuthState } from '../types';

const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: false,
  user: null,
  loading: true,
  setAuth: (isAuthenticated: boolean, user: any) => 
    set({ isAuthenticated, user, loading: false }),
  setLoading: (loading: boolean) => set({ loading }),
  logout: () => set({ isAuthenticated: false, user: null }),
}));

export default useAuthStore;