import React from 'react';
import { Calculator, DollarSign, Clock, TrendingUp } from 'lucide-react';
import { debounce } from '../utils/performance';

const ROICalculator = () => {
  const [teamSize, setTeamSize] = React.useState<number>(20);
  const [avgSalary, setAvgSalary] = React.useState<number>(50000);
  const [employeesReplaced, setEmployeesReplaced] = React.useState<number>(4);

  const calculateROI = React.useCallback(() => {
    const affectedEmployees = Math.round(teamSize * 0.2);
    const currentCost = affectedEmployees * avgSalary;
    const newCost = currentCost * 0.2;
    const totalSavings = currentCost - newCost;
    
    const monthlySavings = totalSavings / 12;
    const productivityGain = totalSavings * 0.25;
    const hoursReclaimed = affectedEmployees * 2080;

    return {
      monthlySavings: Math.round(monthlySavings),
      yearlySavings: Math.round(totalSavings),
      productivityGain: Math.round(productivityGain),
      hoursReclaimed: Math.round(hoursReclaimed)
    };
  }, [teamSize, avgSalary]);

  const results = React.useMemo(() => calculateROI(), [calculateROI]);

  const debouncedSetTeamSize = React.useCallback(
    debounce((value: number) => setTeamSize(value), 100),
    []
  );

  const debouncedSetAvgSalary = React.useCallback(
    debounce((value: number) => setAvgSalary(value), 100),
    []
  );

  React.useEffect(() => {
    setEmployeesReplaced(Math.round(teamSize * 0.2));
  }, [teamSize]);

  return (
    <div className="bg-white dark:bg-gray-800 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Calculate Your ROI
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            See how much your organization can save with AI digital employees
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 dark:bg-gray-700 rounded-xl p-8">
            <div className="space-y-6 flex flex-col justify-center h-full">
              <div>
                <label htmlFor="teamSize" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
                  Total Team Size
                </label>
                <input
                  id="teamSize"
                  type="range"
                  min="5"
                  max="100"
                  value={teamSize}
                  onChange={(e) => debouncedSetTeamSize(Number(e.target.value))}
                  className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
                  aria-valuemin={5}
                  aria-valuemax={100}
                  aria-valuenow={teamSize}
                />
                <div className="flex justify-between text-sm text-gray-600 dark:text-gray-300 mt-2">
                  <span>{teamSize} employees</span>
                  <span>{employeesReplaced} employees optimized</span>
                </div>
              </div>

              <div>
                <label htmlFor="avgSalary" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
                  Average Annual Salary
                </label>
                <input
                  id="avgSalary"
                  type="range"
                  min="40000"
                  max="200000"
                  step="10000"
                  value={avgSalary}
                  onChange={(e) => debouncedSetAvgSalary(Number(e.target.value))}
                  className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
                  aria-valuemin={40000}
                  aria-valuemax={200000}
                  aria-valuenow={avgSalary}
                />
                <div className="flex justify-between text-sm text-gray-600 dark:text-gray-300 mt-2">
                  <span>${avgSalary.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gradient-to-br from-indigo-50 to-purple-50 dark:from-gray-700 dark:to-gray-600 rounded-xl p-6">
              <DollarSign className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-2" aria-hidden="true" />
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                ${results.monthlySavings.toLocaleString()}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">Monthly Savings</p>
            </div>

            <div className="bg-gradient-to-br from-indigo-50 to-purple-50 dark:from-gray-700 dark:to-gray-600 rounded-xl p-6">
              <TrendingUp className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-2" aria-hidden="true" />
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                ${results.yearlySavings.toLocaleString()}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">Annual Savings</p>
            </div>

            <div className="bg-gradient-to-br from-indigo-50 to-purple-50 dark:from-gray-700 dark:to-gray-600 rounded-xl p-6">
              <Calculator className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-2" aria-hidden="true" />
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                ${results.productivityGain.toLocaleString()}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">Added Value</p>
            </div>

            <div className="bg-gradient-to-br from-indigo-50 to-purple-50 dark:from-gray-700 dark:to-gray-600 rounded-xl p-6">
              <Clock className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-2" aria-hidden="true" />
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {results.hoursReclaimed.toLocaleString()}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">Hours Saved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ROICalculator);