import React from 'react';
import { Shield, Zap, Check, Users } from 'lucide-react';

const GuaranteeSection = () => {
  return (
    <div className="mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 place-items-center text-center">
          <div className="flex flex-col items-center space-y-2">
            <Shield className="h-8 w-8 text-indigo-600" />
            <span className="text-gray-600">30-Day Money-Back Guarantee</span>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <Zap className="h-8 w-8 text-indigo-600" />
            <span className="text-gray-600">Instant Setup</span>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <Check className="h-8 w-8 text-indigo-600" />
            <span className="text-gray-600">Cancel Anytime</span>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <Users className="h-8 w-8 text-indigo-600" />
            <span className="text-gray-600">24/7 Support</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuaranteeSection;