import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from 'next-themes';
import { ToastContainer } from 'react-toastify';
import * as Sentry from "@sentry/react";
import { auth0Config } from './config/auth0';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import RequestInfo from './pages/RequestInfo';
import ThankYou from './pages/ThankYou';
import Callback from './components/Callback';
import NotFound from './components/NotFound';
import 'react-toastify/dist/ReactToastify.css';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <ThemeProvider attribute="class" enableSystem={false} defaultTheme="light">
      <Auth0Provider {...auth0Config}>
        <Router>
          <SentryRoutes>
            <Route element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="signup" element={<Signup />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="request-info" element={<RequestInfo />} />
              <Route path="thank-you" element={<ThankYou />} />
              <Route path="callback" element={<Callback />} />
              <Route
                path="dashboard/*"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </SentryRoutes>
          <ToastContainer 
            position="top-right"
            theme="colored"
            className="dark:text-white"
          />
        </Router>
      </Auth0Provider>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);