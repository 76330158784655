import React from 'react';
import { Check, X } from 'lucide-react';

const features = [
  {
    name: "24/7 Availability",
    traditional: false,
    human: false,
    ai: true,
  },
  {
    name: "Instant Scalability",
    traditional: false,
    human: false,
    ai: true,
  },
  {
    name: "Complex Decision Making",
    traditional: false,
    human: true,
    ai: true,
  },
  {
    name: "Zero Training Time",
    traditional: true,
    human: false,
    ai: true,
  },
  {
    name: "Consistent Performance",
    traditional: true,
    human: false,
    ai: true,
  },
  {
    name: "Multi-System Integration",
    traditional: false,
    human: false,
    ai: true,
  }
];

const ComparisonSection = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Why AI Digital Employees?
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Compare the advantages of different workforce solutions
          </p>
        </div>

        <div className="mt-12 overflow-hidden">
          <div className="relative">
            <div className="max-w-4xl mx-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="bg-gray-100 dark:bg-gray-700">
                    <th className="py-4 px-6 text-sm font-semibold text-gray-900 dark:text-white">
                      Capability
                    </th>
                    <th className="py-4 px-6 text-sm font-semibold text-gray-900 dark:text-white text-center">
                      Traditional<br />Automation
                    </th>
                    <th className="py-4 px-6 text-sm font-semibold text-gray-900 dark:text-white text-center">
                      Human<br />Employees
                    </th>
                    <th className="py-4 px-6 text-sm font-semibold text-indigo-900 dark:text-indigo-300 text-center bg-indigo-50 dark:bg-indigo-900/30">
                      AI Digital<br />Employees
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-700">
                  {features.map((feature, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-600' : 'bg-white dark:bg-gray-700'}>
                      <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">
                        {feature.name}
                      </td>
                      <td className="py-4 px-6 text-center">
                        {feature.traditional ? (
                          <Check className="h-5 w-5 text-green-500 mx-auto" />
                        ) : (
                          <X className="h-5 w-5 text-red-500 mx-auto" />
                        )}
                      </td>
                      <td className="py-4 px-6 text-center">
                        {feature.human ? (
                          <Check className="h-5 w-5 text-green-500 mx-auto" />
                        ) : (
                          <X className="h-5 w-5 text-red-500 mx-auto" />
                        )}
                      </td>
                      <td className="py-4 px-6 bg-indigo-50 dark:bg-indigo-900/30 text-center">
                        {feature.ai ? (
                          <Check className="h-5 w-5 text-green-500 mx-auto" />
                        ) : (
                          <X className="h-5 w-5 text-red-500 mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonSection;