import React from 'react';
import { Link } from 'react-router-dom';
import LeadCapture from '../components/LeadCapture';
import ROICalculator from '../components/ROICalculator';

const RequestInfo = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl md:text-6xl">
            <span className="block">Transform Your Business with</span>
            <span className="block text-indigo-600 dark:text-indigo-400">AI Digital Employees</span>
          </h1>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-16">
          <div className="px-6 py-8 sm:p-10">
            <LeadCapture />
          </div>
        </div>

        <ROICalculator />

        <div className="py-8">
          <div className="flex justify-center">
            <Link
              to="/signup"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Get Started Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestInfo;