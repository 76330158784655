import React from 'react';
import { Zap } from 'lucide-react';

const integrations = [
  {
    name: "Video Meetings",
    platforms: ["Zoom", "Teams", "Meet"],
    description: "Join and participate in meetings"
  },
  {
    name: "Communication",
    platforms: ["Email", "Slack", "Teams"],
    description: "Seamless team collaboration"
  },
  {
    name: "Documentation",
    platforms: ["Notion", "Confluence", "SharePoint"],
    description: "Manage content intelligently"
  },
  {
    name: "Project Tools",
    platforms: ["Jira", "Asana", "Monday"],
    description: "Track tasks and progress"
  },
  {
    name: "Business Systems",
    platforms: ["SAP", "Oracle", "Salesforce"],
    description: "Core operations support"
  },
  {
    name: "Analytics",
    platforms: ["Tableau", "Power BI", "Looker"],
    description: "Generate data insights"
  }
];

const IntegrationShowcase = () => {
  return (
    <div className="bg-white dark:bg-gray-900 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Seamless Integration
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Works with your existing tools - no complex setup required
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {integrations.map((integration, index) => (
            <div
              key={index}
              className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <Zap className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                <h3 className="ml-3 text-lg font-medium text-gray-900 dark:text-white">
                  {integration.name}
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4">{integration.description}</p>
              <div className="flex flex-wrap gap-2">
                {integration.platforms.map((platform, idx) => (
                  <span
                    key={idx}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200"
                  >
                    {platform}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntegrationShowcase;