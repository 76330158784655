import React from 'react';
import { DollarSign, Clock, Zap, Shield } from 'lucide-react';

const benefits = [
  {
    icon: DollarSign,
    title: "80% Cost Reduction",
    description: "Significantly reduce operational costs while maintaining high service quality"
  },
  {
    icon: Clock,
    title: "24/7 Availability",
    description: "Ensure continuous operations across all time zones"
  },
  {
    icon: Zap,
    title: "Instant Scalability",
    description: "Scale your digital workforce instantly based on business needs"
  },
  {
    icon: Shield,
    title: "Advanced Security",
    description: "Bank-grade security and compliance controls"
  }
];

const Benefits = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Why Choose Workforce AI?
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Transform your business with AI digital employees that deliver real results
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white dark:bg-gray-700 rounded-lg shadow-sm p-8">
                <benefit.icon className="h-12 w-12 text-indigo-600 dark:text-indigo-400 mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {benefit.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;