import React from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Benefits from '../components/Benefits';
import TestimonialSlider from '../components/TestimonialSlider';
import ROICalculator from '../components/ROICalculator';
import UseCases from '../components/UseCases';
import IntegrationShowcase from '../components/IntegrationShowcase';
import ComparisonSection from '../components/ComparisonSection';
import QuickStartGuide from '../components/QuickStartGuide';
import FAQ from '../components/FAQ';

const Home = () => {
  return (
    <>
      <Hero />
      <div className="py-4" />
      <Features />
      <div className="py-6" />
      <Benefits />
      <div className="py-6" />
      <ROICalculator />
      <div className="py-6" />
      <UseCases />
      <div className="py-6" />
      <IntegrationShowcase />
      <div className="py-6" />
      <ComparisonSection />
      <div className="py-6" />
      <QuickStartGuide />
      <div className="py-6" />
      <TestimonialSlider />
      <div className="py-6" />
      <FAQ />
      
      <div className="bg-gray-100 dark:bg-gray-700 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Want to Learn More?
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
            Get detailed information about how AI employees can transform your business
          </p>
          <div className="mt-8 flex justify-center gap-4">
            <Link
              to="/request-info"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Request Information
            </Link>
            <Link
              to="/signup"
              className="inline-flex items-center px-6 py-3 border border-gray-300 dark:border-gray-600 text-base font-medium rounded-md text-indigo-600 dark:text-indigo-400 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Get Started Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;