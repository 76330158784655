import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "What are the technical requirements?",
    answer: "Our AI digital employees work with standard business tools and require no special hardware. You just need stable internet connectivity and access to your usual collaboration platforms."
  },
  {
    question: "How long does implementation take?",
    answer: "Most organizations are up and running within hours. Our streamlined onboarding process ensures quick deployment and immediate value generation."
  },
  {
    question: "How do AI employees integrate with our existing tools?",
    answer: "Our AI employees seamlessly connect with your current software stack through secure API integrations. No coding or technical expertise required."
  },
  {
    question: "What kind of support do you provide?",
    answer: "We provide 24/7 dedicated support, comprehensive documentation, and ongoing optimization assistance to ensure maximum value from your AI workforce."
  },
  {
    question: "How do you ensure data security?",
    answer: "We implement enterprise-grade encryption, secure API connections, and strict access controls to protect your sensitive information."
  },
  {
    question: "Can AI employees handle complex decision-making?",
    answer: "Yes, our AI employees use advanced algorithms to make intelligent decisions based on your business rules and historical data patterns."
  }
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white dark:bg-gray-900 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Everything you need to know about AI digital employees
          </p>
        </div>

        <div className="mt-8 max-w-3xl mx-auto">
          <dl className="space-y-6">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-gray-50 dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <dt>
                  <button
                    className="flex justify-between w-full px-6 py-4 text-left"
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="text-lg font-medium text-gray-900 dark:text-white">
                      {faq.question}
                    </span>
                    <span className="ml-6 flex-shrink-0">
                      {openIndex === index ? (
                        <ChevronUp className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                      ) : (
                        <ChevronDown className="h-6 w-6 text-gray-400" />
                      )}
                    </span>
                  </button>
                </dt>
                <dd
                  className={`${
                    openIndex === index ? 'block' : 'hidden'
                  } px-6 pb-4 text-gray-600 dark:text-gray-300`}
                >
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FAQ;